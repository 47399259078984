
function LeftMenuProducts(props) {
    const { pState } = props
    const brands = [{ brand: "تلویزیون" }, { brand: "تصویه آب" }, { brand: "empty" }, { brand: "empty" },]

    return (
        <div className='left-menu-products container'>

            <div className='d-grid'>
                <h6>دسته ها</h6>
                {pState.map(ev => (
                    <div key={ev._id}>
                        <i className='fa fa-angle-left' />
                        <a name="men" href="#shop/category">{ev.category}</a>
                    </div>
                ))}
            </div>

            <div className='d-grid py-3'>

                <h6 style={{ marginBottom: '-5px' }}> برند ها</h6>
                {pState.map((ev) => (
                    <div key={ev._id} className='d-flex p-l-10' style={{ marginBottom: '-25px' }}>
                        <input type='checkbox' />
                        <p className='my-3'>&nbsp; {ev.brand}</p>
                    </div>
                ))}

            </div>

            <h6 className="my-3" >Customer Review</h6>
            <p style={{ fontSize: "30px", color: "yellow" }}>&#9733;&#9733;&#9733;&#9733;&#9733;</p>

            <h6>Price</h6>
            {pState.map((ev, id) => (
                <div key={id} className='d-flex p-l-10' style={{ marginBottom: '-25px' }}>
                    <input type='checkbox' />
                    <p className='my-3'>&nbsp; {ev.category}</p>
                </div>
            ))}



            <h6 style={{ marginTop: "33px" }} >Seller</h6>

            {brands.map((ev, id) => (
                <div key={id} className='d-flex p-l-10' style={{ marginBottom: '-25px' }}>
                    <input type='checkbox' />
                    <p className='my-3'>&nbsp; {ev.brand}</p>
                </div>
            ))}









































        </div>
    )
}

export default LeftMenuProducts