
import { useEffect, useState } from "react";
import { getUserCartProducts } from "../features/product/productSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap"

function Cart() {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'))


  useEffect(() => {
    (async () => {
      if (!user) return <a href="/login" className="cart-a-tag">ابتدا وارد شوید</a>
      const userState = user.cart
      let arr = []
      userState.map(ev => {
        arr.push(ev.productId)
      })
      dispatch(getUserCartProducts(arr))

    })();
  }, [])


  const productState = useSelector(state => state.product.products)
console.log(productState);
  const productMap = productState.map(ev => {
    return (
      <div key={ev._id} className="cart-products">

        <p>{ev.name}</p>
        <img src={`${ev.images.title}`} width="100px" />

      </div>
    )
  })

  if (!user) return <a href="/login" className="cart-a-tag">ابتدا وارد شوید</a>
  if (productState.length < 1) return <p href="/login" className="cart-a-tag">سبد خالی است</p>

  return (
    <div style={{ overflow: "scroll" }}>

      {productMap}

      <a href={'/order'}><Button>ثبت خرید</Button></a>
    </div>
  )
}

export default Cart;