import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../features/product/productSlice";
import digi_banner from '../images/digi_banner.gif'
import watch from '../images/watch.jpg'
import tv from '../images/tv.jpg'
import bahman_box from '../images/bahman-box.webp'
import bahman_one1 from '../images/bahman-one1.jpeg'
import headphone from '../images/headphone.jpg'
import camera from '../images/camera.jpg'
import brand1 from '../images/brand-01.png'
import brand2 from '../images/brand-02.png'
import brand3 from '../images/brand-03.png'
import brand4 from '../images/brand-04.png'
import brand5 from '../images/brand-05.png'
import brand6 from '../images/brand-06.png'
import brand7 from '../images/brand-07.png'
import brand8 from '../images/brand-08.png'

function Body() {
  const dispatch = useDispatch();
  const productState = useSelector((state) => state.product.products);
  // const blogState = useSelector((state) => state.blog.blogs);
  // const blogState = []


  // <!-- Start Single Page Apps for GitHub Pages -->
  // <script type="text/javascript">
  //   (function(l) {
  //     if (l.search[1] === '/' ) {
  //       var decoded = l.search.slice(1).split('&').map(function(s) { 
  //         return s.replace(/~and~/g, '&')
  //       }).join('?');
  //       window.history.replaceState(null, null,
  //           l.pathname.slice(0, -1) + decoded + l.hash
  //       );
  //     }
  //   }(window.location))
  // </script>
  // <!-- End Single Page Apps for GitHub Pages -->


  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    dispatch(getProducts());
    // dispatch(getAllBlogs());
  };

  return (
    <>
      <section className="gray home">
        {/* DIGI BANNER IMAGE */}
        <img src={digi_banner} className="body-banner rounded-3 py-4 " alt="main banner" />

      </section>

      {/* SERVICES? */}
      {/* <section className="py-5 gray container-fluid" >
        <div className="d-flex justify-content-around container-fluid col-12" style={{backgroundColor:'white'}} >
          {services.map((ev) => (
              <div className="services" key={ev.id} >
                <img src={ev.image} alt="Services" />
                <div>
                  <h6>{ev.title}</h6>
                  <p>{ev.tagline}</p>
                </div>
              </div>
            ))}
        </div>
      </section> */}

      <section className="gray container-fluid" style={{ overflow: "scroll", width: "100%" }}>
        <div className="categories">
          <a href="/categories" className="d-flex align-items-center gap-10" id="no-a">
            <img src={"https://dkstatics-public.digikala.com/digikala-products/3a7b568fd437b77be10fb952ea8684540128c67d_1662275830.jpg?x-oss-process=image/resize,m_lfit,h_800,w_800/format,webp/quality,q_90"} alt='services' width="100px" />
            <div>
              <h6>یخچال و فریزر</h6>
              <p>ارسال سریع روزهای کاری</p>
            </div>
          </a>

          <a href="/categories" className="d-flex align-items-center gap-10" id="no-a">
            <img src={"https://dkstatics-public.digikala.com/digikala-products/6ee2bdf01852e97b46b9a01be7a1465e5714bcee_1702881396.jpg?x-oss-process=image/resize,m_lfit,h_800,w_800/format,webp/quality,q_90"} alt='services' width="100px" />
            <div>
              <h6>ماشین ظرفشویی</h6>
              <p>تخفیف فصل</p>
            </div>
          </a>

          <a href="/categories" className="d-flex align-items-center gap-10" id="no-a">
            <img src={tv} alt="Services" />
            <div>
              <h6>ارسال سریع</h6>
              <p>محصولات بالای ده ملیون تومن</p>
            </div>
          </a>

          <a href="/categories" className="d-flex align-items-center gap-10" id="no-a">
            <img src={tv} alt="Services" />
            <div>
              <h6>ارسال سریع</h6>
              <p>محصولات بالای ده ملیون تومن</p>
            </div>
          </a>
          
          </div>


          {/* ------------------------------------------------------------------- */}


          {/* -------------------------------------------------------------------- */}




      </section>
      <section className="container-fluid py-4 gray">
        <h3 className="py-4">منتخب جدید ترین محصولات</h3>

        <div className="product-card-body">
          {productState?.map((ev) => (
            <ProductCard
              key={ev._id}
              id={ev._id}
              name={ev.name}
              price={ev.price}
              // description={ev.description}
              images_title={ev.images.title}
              images_others={ev.images.others}
              color={ev.color}
            />
          ))}
        </div>
      </section>


      <section className="gray container-fluid">
        <h3 className="py-4"> پر فروش ترین های اخیر</h3>
        <div className="product-card-body">
          {productState?.map((ev) => {
            if (ev.best_seller) {
              return (
                <ProductCard
                  key={ev._id}
                  id={ev._id}
                  name={ev.name}
                  price={ev.price}
                  images_title={ev.images.title}
                  images_others={ev.images.others}
                  color={ev.color}
                  rating={ev.rating}
                  review={ev.review}
                />
              );
            }
          })}
        </div>
      </section>

      <section className="gray">
        <Marquee>
            <img src={brand2} alt="brand"/>
            <img src={brand7} alt="brand" />
        </Marquee>
      </section>


    </>
  );
}

export default Body;
