import React from "react";
import { Link } from "react-router-dom";
import { BsLinkedin, BsYoutube, BsInstagram, BsGithub } from "react-icons/bs";
import newsletter from "../images/newsletter.png"

function Footer() {
  return (
    <>
      <footer className="py-3">
        <div className="container-xxl">
          <div className="email-signup">
            <div className="">
              <div className="footer-top-data d-flex gap-30 align-items-center">
                <img src={newsletter} alt="newsletter" />
                <h3 className="mb-0 text-white">با ما در ارتباط باشید  </h3>
              </div>

            </div>
            <div className="py-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control py-2"
                  placeholder="ایمیل خود را وارد کنید"
                />
                <span className="input-group-text p-3" id="c-p"> عضویت</span>
              </div>
            </div>
            
          </div>
        </div>
      </footer>

      <footer className="py-4">
        <div className="container-xxl">
          <div className="rowwww">
            {/* <div className="col-3"> */}
              <h4 className="text-white mb-4">ارتباط با ما</h4>
              <div className="text-white">
                <address>
                  تهران, خیابان جمهوری,<br /> ابتدای کاخ گلستان,<br />{" "}
               فروشگاه های زنجیره ای آسان سرا
                </address>
                <a href="tel: +91 210015" className="mt-1">
                ۶۸۸۰ ۴۴۲۲ ۲۱ ۹۸+‍‍‍‍   
                </a>
                <a
                  href="mailto:mettihew@gmail.com"
                  className="mt-4 d-block mb-4 text-white"
                >
                  kasan@gmail.com
                </a>
                <div className="social-icons d-flex align-items-center gap-30 ">
                  <p className="text-white">
                    <BsGithub className="fs-4" />
                  </p>
                  <p className="text-white">
                    <BsLinkedin className="fs-4" />
                  </p>
                  <p className="text-white">
                    <BsInstagram className="fs-4" />
                  </p>
                  <p className="text-white">
                    <BsYoutube className="fs-4" />
                  </p>
                </div>
              </div>
            </div>


            {/* <div className="col-3">
              <h4 className="text-white mb-4">Information</h4>
              <div className="footer-links d-flex flex-column">
                <Link to="/refund-policy" className="text-white py-2 mb-1">
                  Returned Policy
                </Link>
                <Link to="/privacy-policy" className="text-white py-2 mb-1">
                  Privacy Policy
                </Link>
                <Link to="/shipping-policy" className="text-white py-2 mb-1">
                  Shipping Policy
                </Link>
                <Link to="/term-conditions" className="text-white py-2 mb-1">
                  Terms & Conditions
                </Link>
                <Link to="/" className="text-white py-2 mb-1">
                  Blogs
                </Link>
              </div>
            </div> */}

            {/* <div className="col-3">
              <h4 className="text-white mb-4">Account</h4>
              <div className="footer-links d-flex flex-column">
                <Link className="text-white py-2 mb-1">About Us</Link>
                <Link className="text-white py-2 mb-1">Faq</Link>
                <Link className="text-white py-2 mb-1">Contact</Link>
                <Link className="text-white py-2 mb-1">Call</Link>
              </div>
            </div> */}

            {/* <div className="col-3">
              <h4 className="text-white mb-4">Quick Links</h4>
              <div className="footer-links d-flex flex-column">
                <Link className="text-white py-2 mb-1">Laptops</Link>
                <Link className="text-white py-2 mb-1">Headphones</Link>
                <Link className="text-white py-2 mb-1">Tablets</Link>
                <Link className="text-white py-2 mb-1">Watch</Link>
              </div>
            </div> */}
          </div>
        {/* </div> */}
      </footer>

      <footer className="py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-white">
                &copy; {new Date().getFullYear()}; Powered by Mettihew
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;