import { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import ProductCard from "../components/ProductCard";

import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../features/product/productSlice";

function OurStore() {
  const [grid, setGrid] = useState(4);
  const Color = 'red'

  const dispatch = useDispatch();
  const productState = useSelector((state) => state.product.products);

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  return (
    <>
      <div className="py-5 home-wrapper-2 container-xxl row">
        <div className="col-3">
          <div className="filter-card mb-3">
            <h3 className="filter-title">Shop By Categories</h3>
            <div>
              <ul className="ps-0">
                <li>Watch</li>
                <li>TV</li>
                <li>Camera</li>
                <li>Laptop</li>
              </ul>
            </div>
          </div>

          <div className="filter-card mb-3">
            <h3 className="filter-title">Filter By</h3>

            <div>
              <h5 className="sub-title">Availablity</h5>

              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ava"
                  />
                  <label className="form-check-label" htmlFor="ava">
                    In Stock(1)
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ava"
                  />
                  <label className="form-check-label" htmlFor="ava">
                    Out of Stock(0)
                  </label>
                </div>
              </div>

              <h5 className="sub-title">Price</h5>

              <div className="d-flex align-items-center gap-10">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    id="from"
                    className="form-control"
                    placeholder="From"
                  />
                  <label htmlFor="from">From</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    id="to"
                    className="form-control"
                    placeholder="To"
                  />
                  <label htmlFor="to">To</label>
                </div>
              </div>

              <h5 className="sub-title">Colors</h5>
              <div>
                <div className="d-flex flex-wrap">
                  <Color />
                </div>
              </div>

              <h5 className="sub-title">Size</h5>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="color-1"
                  />
                  <label className="form-check-label" htmlFor="color-1">
                    S (2)
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="color-2"
                  />
                  <label className="form-check-label" htmlFor="color-2">
                    M (2)
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="filter-card mb-3">
            <h3 className="filter-title">Prodcut Tags</h3>
            <div>
              <div className="product-tags d-flex flex-wrap align-items-center">
                <span className="badge bg-secondary text-secondary rounded-3 py-2 px-3">
                  Headphone
                </span>
              </div>

              <div className="product-tags d-flex flex-wrap align-items-center">
                <span className="badge bg-secondary text-secondary rounded-3 py-2 px-3">
                  Laptop
                </span>
              </div>

              <div className="product-tags d-flex flex-wrap align-items-center">
                <span className="badge bg-secondary text-secondary rounded-3 py-2 px-3">
                  Mobile
                </span>
              </div>

              <div className="product-tags d-flex flex-wrap align-items-center">
                <span className="badge bg-secondary text-secondary rounded-3 py-2 px-3">
                  Wire
                </span>
              </div>
            </div>
          </div>

          <div className="filter-card mb-3">
            <h3 className="filter-title">Random Product</h3>
            <div>
              <div className="random-products d-flex">
                <div>
                  <div className="d-flex">
                    <div className="w-75">
                      <img
                        src="images/headphone.jpg"
                        alt="tab"
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <p className="fs-6">
                        Kids headphones bulk 10 pack multi colored for students
                      </p>
                      {/* <ReactStars
                        count={5}
                        size={24}
                        activeColor="#ffd700"
                        value={4}
                      /> */}
                      <p className="price">100$</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="random-products d-flex">
                <div>
                  <div className="d-flex">
                    <div className="w-75">
                      <img
                        src="images/tab.jpg"
                        alt="tab"
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <p className="fs-6">
                        Kids headphones bulk 10 pack multi colored for students
                      </p>
                      <ReactStars
                        count={5}
                        size={24}
                        activeColor="#ffd700"
                        value={4}
                      />
                      <p className="price">100$</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-9">
          <div className="filter-sort-grid d-flex align-items-center justify-content-between ">
            <div className="d-flex align-items-center gap-10">
              <p className="mb-0 d-block w-50">Sort By</p>
              <select name="" className="form-control from-select" id="">
                <option value="price1">option one</option>
                <option value="price2">option two</option>
                <option value="price3">option three</option>
              </select>
            </div>

            <div className="d-flex align-items-center gap-10">
              <p className="totalproducts mb-0 ">21 Product</p>
              <div className="d-flex gap-10 align-items-center grid">
                <img
                  onClick={() => {
                    setGrid(3);
                  }}
                  src="images/gr4.svg"
                  alt="grid"
                  className="d-block img-fluid"
                />
                <img
                  onClick={() => {
                    setGrid(4);
                  }}
                  src="images/gr3.svg"
                  alt="grid"
                  className="d-block img-fluid"
                />
                <img
                  onClick={() => {
                    setGrid(6);
                  }}
                  src="images/gr2.svg"
                  alt="grid"
                  className="d-block img-fluid"
                />
                <img
                  onClick={() => {
                    setGrid(12);
                  }}
                  src="images/gr.svg"
                  alt="grid"
                  className="d-block img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            {productState &&
              productState?.map((ev) => {
                return (
                  <ProductCard
                    grid={grid}
                    key={ev._id}
                    id={ev._id}
                    title={ev.title}
                    description={ev.description}
                    images={ev.images.title}
                    color={ev.color}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default OurStore;