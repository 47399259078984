
import { Link} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BsSearch } from "react-icons/bs";
import compare from "../images/compare.svg";
import wishlist from "../images/wishlist.svg";
import userSVG from "../images/user.svg";
import cart from "../images/cart.svg";
import Drawer from '../components/Drawer'
import { searchProduct } from "../features/product/productSlice";



function Header() {
  const dispatch = useDispatch()
  const [search, setSearch] = useState(false)
  const navigate = useNavigate()
  let data;
  let search2;
  let user = JSON.parse(localStorage.getItem('user'))
  let userState = null
  if(user){
     userState = user.cart
  }
  const [tAmount, setTAmount] = useState(null);

  useEffect(() => {
    if (userState === "bla") {
      let sum = 0;
      for (let i = 0; i < 2; i++) {
        sum += userState[i].price * userState[i].quantity;
      }
      setTAmount(sum);
    }
  });

  const searchHandler = () => {
    dispatch(searchProduct({text: search}));
  }

  if (!userState) userState = []

  return (
    <div className="header">

<div className="header-line1 py-3 container-fluid">



      {/* input */}
      <div className="header-domain-and-search">
          <div className="input-group">
            <div className="input-group-text" onClick={searchHandler}><BsSearch /></div>
            <input className="header-search-input p-2" type="text" placeholder="جستجو کنید" onChange={(ev) => setSearch(ev.target.value)} />
          </div>
        <div><a className="eesy" href="/" id="no-a"><h2>eesy</h2><p>خریدی آسان</p></a></div>
        </div>



        {/* Digitic */}

        {/* links that have icons */}
          <div className="header-link-that-have-icons">

            <div>
              <Link
                to="/compare-product"
                className="d-flex align-items-center gap-10 text-white"
              >
                <img src={compare} alt="compare" />
                <p className="mb-0 ">
                  مقایسه
                  <br /> محصولات
                </p>
              </Link>
            </div>
            <div>
              <Link
                to="/wishlist"
                className="d-flex align-items-center gap-10 text-white"
              >
                <img src={wishlist} alt="wishlist" />
                <p className="mb-0">
                  مورد علاقه ها<br /> لیست شما
                </p>
              </Link>
            </div>
            <div>
              <Link
                to="/login"
                className="d-flex align-items-center gap-10 text-white"
              >
                <img src={userSVG} alt="user" />
                {!user && (
                  <p className="mb-0">
                   ورود به<br /> حساب من
                  </p>
                )}
                {user && (
                  <p className="mb-0">
                    خوش آمدید <br /> {user.name}, عزیز
                  </p>
                )}
              </Link>
            </div>

            <div>
              <Link to="/cart" className="d-flex align-items-center gap-10 text-white">
                <div className="d-flex flex-column ">
                  <span className="badge bg-white text-dark">
                    {userState.length ? userState.length : 0}
                  </span>
                  <p className="mb-0" > {tAmount ? tAmount : 0} $</p>
                </div>

                <img src={cart} alt="cart" />
              </Link>
            </div>
        </div>
      </div>


      {/* LINE TWO  */}


      <div className="header-line2 container-fluid">
      <Drawer />
        <div className="header-items">
          <a href={`/products`} style={{color:'green'}}>محصولات</a>
          <a href={`/categories`} >دسته بندی ها</a>
          <a href={`/contact`}>بشتیبانی</a>
        </div>
      </div>


    </div>
  );
}

export default Header;